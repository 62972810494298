<template>
    <div>
        <div class="container-fluid p4" style="margin-top: 35px">
            <b-card>
                <b-card-title style="margin-bottom: 45px">
                    Background Images
                    <div class="float-right">
                        <b-btn variant="success" size="md"
                                 @click="GotoAdd"> +
                        </b-btn>
                    </div>
                </b-card-title>
                <b-modal id="addImageModal" modal-class="modal-right" size="lg" hide-footer title="Add Product">
                <AddImage @success="AddedNew"></AddImage>
                </b-modal>
                <VueTable ref="table" :url="apiBase" :fields="fields" :perPage="10">
                    <template slot="bg_image" slot-scope="props">
                        <img :src='prefix + props.rowData.bg_image'
                             width="96" v-if="props.rowData.bg_image">
                        <img src="../../../assets/img/logo-white.svg" width="96" v-if="!props.rowData.bg_image">
                    </template>
                    <template slot="actions" slot-scope="props">
                        <div class="btn-group">
                            <b-btn class="btn btn-info btn-sm" @click="editItem(props.rowData)">
                                 Edit</b-btn>
                            <b-btn v-if="props.rowData.is_active" class="btn btn-success btn-sm" @click="changeStatus(props.rowData.id)">
                                 Deactivate</b-btn>
                            <b-btn v-else class="btn btn-warning btn-sm" @click="changeStatus(props.rowData.id)">
                                 Activate</b-btn>
                            <b-btn class="btn btn-danger btn-sm" @click="deleteItem(props.rowData.id)">
                                 Delete</b-btn>
                        </div>
                    </template>
                </VueTable>
            </b-card>
            <b-modal id="editImageModal" modal-class="modal-right" size="lg" hide-footer title="Edit Product">
                <EditImage :model="editingModel" @saved="ItemEdited"></EditImage>
            </b-modal>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '@/data/urls';
import VueTable from 'v-tables/src/VueTable';
import AddImage from '@/views/admin/background-image/AddImage';
import EditImage from '@/views/admin/background-image/EditImage';

export default {
    name: 'ListImage',
    data () {
        return {
            editingModel: {
                id: '',
                bg_image: null
            },
            prefix: urls.baseUrl,
            editingImage: null,
            apiBase: urls.admin.backgroundImage.list,
            fields: [
                {
                    name: 'id',
                    sortField: '',
                    title: 'ID',
                    titleClass: ''
                },
                {
                    name: '__slot:bg_image',
                    sortField: 'bg_image',
                    titleClass: ''
                },
                {
                    name: 'is_active',
                    sortField: 'is_active',
                    title: 'IS Active',
                    titleClass: ''
                },
                {
                    name: '__slot:actions',
                    title: '',
                    titleClass: 'center aligned text-right',
                    dataClass: 'center aligned text-right'
                }
            ]
        };
    },
    methods: {
        GotoAdd () {
            this.$bvModal.show('addImageModal');
        },
        AddedNew () {
            this.$bvModal.hide('addImageModal');
            this.$refs.table.refreshTable();
        },
        editItem (item) {
            this.editingImage = item;
            this.editingModel.id = item.id;
            this.editingModel.bg_image = item.bg_image;
            this.$bvModal.show('editImageModal');
        },
        ItemEdited () {
            this.editingImage.id = this.editingModel.id;
            this.editingImage.bg_image = this.editingModel.bg_image;
            this.$bvModal.hide('editImageModal');
            this.editingImage = null;
            this.$refs.table.refreshTable();
        },
        deleteItem (item) {
            const component = this;
            const submit = confirm('Are you sure to delete..!');
            if (!submit) {
                return;
            }
            axios.form(urls.admin.backgroundImage.delete, { id: item }).then(function (data) {
                const json = data.data;
                if (json.error === false) {
                    component.$notify('Deleted', 'Product is Deleted Successfully',
                        {
                            duration: 3000,
                            permanent: false,
                            color: 'warning'
                        }
                    );
                    component.$refs.table.refreshTable();
                } else {
                    console.log(json);
                    component.$notify('Error', json.errors,
                        {
                            duration: 3000,
                            permanent: false
                        }
                    );
                }
            });
        },
        changeStatus (product) {
            if (!confirm('Are you sure?')) {
                return;
            }
            const component = this;

            axios.form(urls.admin.backgroundImage.ChangeStatus, {
                id: product
            }).then(function (data) {
                const json = data.data;
                if (json.error === false) {
                    component.$notify('Success', '');
                    component.$refs.table.refreshTable();
                } else {
                    console.log(json);
                    component.$notify('Error', json.errors,
                        {
                            duration: 3000,
                            permanent: false
                        }
                    );
                }
            });
        }
    },
    components: { AddImage, EditImage, VueTable }
};
</script>

<style scoped>

</style>
